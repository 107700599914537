import React, { useContext } from 'react'
import ImportDevices from './Devices/importdevices';
import Exportdevices from './Devices/exportdevices';
import ImportUsers from './Users/importusers';
import Exportusers from './Users/exportusers';
import Importtickets from './Tickets/importtickets';
import Exporttickets from './Tickets/exporttickets';
import { UserContext } from '../../App';
import ExportMasterInventory from './MasterInventory/ExportMasterInventory';
import ImportInventory from './MasterInventory/importinventory';
import ImportGoogleDevices from './GoogleIntegration/ImportGoogleDevices';
import CustomCsvImports from './CustomCsvImports';
import ImportBuildingRoom from './BuildingRoom/ImportBuildingRoom';
import ExportbuildingroomComp from './BuildingRoom/exportbuildingroom';
import PageHead from '../../Components/PageHead';
import ImportIQDevices from './IncidentIQ/ImportIQDevices';

export function ImportExport() {
    const { UserData } = useContext(UserContext);
    return (
        <>
            <PageHead heading={"Import/Export"} showsearch={false} />
            {UserData.MenuAccessFlag != 5 ?
                <div className='GridBox mt-3 p-3'>
                    <div className='row'>
                        <div className='col-md-6 py-2'>
                            <div className="greyBox" style={{ height: "100%" }}>
                                <ImportGoogleDevices />
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className="greyBox" style={{ height: "100%" }}>
                                <ImportIQDevices />
                            </div>
                        </div>
                        <div className='col-12 py-2'>
                            <div className="greyBox" style={{ height: "100%" }}>
                                <CustomCsvImports />
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className="greyBox" style={{ height: "100%" }}>
                                <ImportDevices />
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className="greyBox" style={{ height: "100%" }}>
                                <Exportdevices />
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className="greyBox" style={{ height: "100%" }}>
                                <ImportUsers />
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className="greyBox" style={{ height: "100%" }}>
                                <Exportusers />
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className="greyBox" style={{ height: "100%" }}>
                                <Importtickets />
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className="greyBox" style={{ height: "100%" }}>
                                <Exporttickets />
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className="greyBox" style={{ height: "100%" }}>
                                <ImportBuildingRoom />
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className="greyBox" style={{ height: "100%" }}>
                                <ExportbuildingroomComp />
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='GridBox mt-2 p-3'>
                    <div className='row'>
                        <div className='col-md-6 py-2'>
                            <div className="greyBox" style={{ height: "100%" }}>
                                <ImportInventory />
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className="greyBox" style={{ height: "100%" }}>
                                <ExportMasterInventory />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

import React, { useEffect, useState } from 'react'
import Popup from '../../../Components/Popup';
import { CheckValidation } from '../../../Components/Validations';
import { getTodayDate, HideLoder, LogInSchoolID, LogInUserID, ShowLoder } from '../../../JS/Common';
import { ApiGetCall, ApiPostCall } from '../../../JS/Connector';
import AlertsComp from '../../../Components/AlertsComp';
import { useNavigate } from 'react-router-dom';

export default function ImportIQDevices() {
    const [isDetailPopup, setisDetailPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [showButtons, setshowButtons] = useState(true);
    const [NewAlerts, setNewAlerts] = useState("");
    const [returnStatus, setreturnStatus] = useState("");
    const [popupData, setPopupData] = useState({
        api: "",
        token: "",
    });
    useEffect(() => {
        GetCredentials();
    }, [])
    async function GetCredentials() {
        await ApiGetCall("/GetIncidentCredentials/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.msg) {
                    setPopupData({ ...popupData, api: responseRs?.msg?.api, token: responseRs?.msg?.token });
                }
            }
        });
    }
    async function AddIQData() {
        var isFormValid = CheckValidation({ formID: 'IncidentData' });
        if (!isFormValid) return;
        setLoading(true);
        var today = getTodayDate();
        var raw = JSON.stringify({
            schID: LogInSchoolID,
            api: popupData.api,
            token: popupData.token,
            expiresAt: today
        });
        await ApiPostCall("/AddIncidentCredentials", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setreturnStatus(result)
                setshowButtons(false);
            }
        });
    }
    const modalBody = (
        <div id="IncidentData" className="parent row">
            {showButtons == true && (
                <>
                    <div className='font-13 pe-0'>
                        <label className='fw-semibold pb-3 ps-4'>You can find the API URL and Token by following these steps:</label>
                        <ul>
                            <li>Go to incidentIQ website and login as an Administrator with your credentials. </li>
                            <li className='pt-2'>On the left side of the screen, you'll see a menu. Look for the section labeled Admin and click on it.</li>

                            <li className='pt-2'>Inside the Admin section, find and click on Developer Tools.</li>

                            <li className='pt-2'>Once you're in the Developer Tools, you'll see a list of your current API tokens.</li>
                            <li className='pt-2'>If you need a new token, there's an option to create one right there. </li>
                            <li className='pt-2'>Once you collect your API URL and Token from your incidentIQ account, use them below to connect with Rocket. </li>
                        </ul>
                    </div>

                    <div className='col-md-6 ps-5 py-3'>
                        <div className='FormLabel'>Enter API URL<label className='redText'>*</label></div>
                        <input type='text' className="form-control" autoComplete='off' name='api' required
                            value={popupData.api}
                            onChange={(e) => setPopupData({ ...popupData, api: e.target.value })} />
                        <span className="form-text invalid-feedback">
                            *required
                        </span>
                    </div>
                    <div className='col-md-6 pe-5 py-3'>
                        <div className='FormLabel'>Enter Token<label className='redText'>*</label></div>
                        <input type='text' className="form-control" autoComplete='off' name='token' required
                            value={popupData.token}
                            onChange={(e) => setPopupData({ ...popupData, token: e.target.value })} />
                        <span className="form-text invalid-feedback">
                            *required
                        </span>
                    </div>
                </>
            )}
            {!showButtons &&
                <div className='col-12 text-center'>
                    {returnStatus == "success" ?
                        <label className='mb-3 schoolColorStatus'>
                            Your incidentIQ account has been connected successfully, would you like to import existing Students and Assets into Rocket now?
                        </label>
                        : returnStatus == "error" ?
                            <label className='mb-3 redText'>
                                Sorry, we could not connect your incidentIQ account with Rocket. Please make sure you have entered correct credentials, click here to try again. If you continue facing this issue, please contact Rocket administrator from here.
                            </label>

                            :
                            <></>
                    }

                </div>

            }
        </div>
    );

    async function saveAssets() {
        setLoading(true);
        var raw = JSON.stringify({
            schID: LogInSchoolID,
            k12UserID: LogInUserID
        });
        await ApiPostCall("/fetchAsset", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Assets Added Successfully."} />);
                    setisDetailPopup(false);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                        navigate('/manage-device');
                    }, 2000);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }

    const modalfooter = (
        <div className='row'>
            <div className='col-10 ps-0 font-13'>
                <div className='text-justify schoolColorStatus' style={{ letterSpacing: "normal" }}>
                    <label><label className='fw-semibold '>Notes : </label> Please note that your incidentIQ API URL and Token are saved encrypted to connect with Rocket. Your data is safeguarded within your account by our security protocols. </label>
                </div>
            </div>
            <div className='col-2 px-0 text-end'>
                <label className="ms-2 cursor-pointer" onClick={(e) => {
                    setPopupData({ ...popupData, api: "", token: "" });
                    setisDetailPopup(false);
                    setshowButtons(true)
                }}><u>Cancel</u></label>
                <button className={`SaveBtn ms-2`} onClick={AddIQData}>Save</button>
            </div>
        </div>

    )
    const modalfooterimport = (
        <>
            {returnStatus == "success" ?
                <>
                    <label className="ms-2 cursor-pointer" onClick={(e) => {
                        setPopupData({ ...popupData, api: "", token: "" });
                        setisDetailPopup(false);
                        setshowButtons(true);
                        GetCredentials();
                        setNewAlerts(<AlertsComp show={true} variant="success" msg={"Your data is stored securely within Rocket."} />);
                        setTimeout(() => {
                            setNewAlerts(<AlertsComp show={false} />);
                        }, 1500);
                    }}><u>Skip</u></label>
                    <button className={`SaveBtn ms-2`} onClick={saveAssets}>Yes Continue</button>
                </>
                :
                <>
                    <label className="ms-2 cursor-pointer" onClick={(e) => {
                        setPopupData({ ...popupData, api: "", token: "" });
                        setisDetailPopup(false);
                        setshowButtons(true);
                        GetCredentials();
                    }}><u>Cancel</u></label>
                </>
            }
        </>

    )
    return (
        <div>
            {NewAlerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle pb-2'>Import Your Incident IQ Devices</span>
            </div>
            <hr className='my-0' />
            {popupData.api != "" && popupData.token != "" ?
                <>
                    <div className="mt-3 mb-2">
                        <p className="text-justify" style={{ fontSize: "13px" }}>
                            Your account is already connected to Rocket. Click the button below to import assets and student information.
                        </p>
                    </div>
                    <div className='col-md-12 mt-3'>
                        <div className="row">
                            <div className="col-12">
                                <button className="MicrosoftGoogleBtn" onClick={saveAssets}>
                                    <div className="">
                                        <img src="/images/Incident_iq.svg" className="img-fluid pe-2" />
                                        Import Assets & Students
                                        <span className='ms-3'>BETA</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="mt-3 mb-2">
                        <p className="text-justify" style={{ fontSize: "13px" }}>
                            Connect your incidentIQ account with Rocket for seamless sync between two systems. Click below to get started!
                        </p>
                    </div>
                    <div className='col-md-12 mt-3'>
                        <div className="row">
                            <div className="col-12">
                                <button className="MicrosoftGoogleBtn" onClick={(e) => setisDetailPopup(true)}>
                                    <div className="">
                                        <img src="/images/Incident_iq.svg" className="img-fluid pe-2" />
                                        Integrate incidentIQ Account
                                        <span className='ms-3'>BETA</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            }

            <Popup isshow={isDetailPopup} size={"lg"} title={'Add Credentials'}
                closePopup={(e) => {
                    setPopupData({ ...popupData, api: "", token: "" });
                    setisDetailPopup(false);
                    setshowButtons(true)
                }}
                modalBody={modalBody}
                modalfooter={showButtons ? modalfooter : modalfooterimport}
            />
        </div>
    )
}
